/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceRequestDestinationType,
    ApiAssistanceRequestDestinationTypeFromJSON,
    ApiAssistanceRequestDestinationTypeFromJSONTyped,
    ApiAssistanceRequestDestinationTypeToJSON,
} from './';

/**
 * destination for full data api
 * @export
 * @interface ApiFullDataDestination
 */
export interface ApiFullDataDestination {
    /**
     * 
     * @type {string}
     * @memberof ApiFullDataDestination
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFullDataDestination
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiFullDataDestination
     */
    workshopId?: number;
    /**
     * 
     * @type {ApiAssistanceRequestDestinationType}
     * @memberof ApiFullDataDestination
     */
    type?: ApiAssistanceRequestDestinationType;
    /**
     * 
     * @type {number}
     * @memberof ApiFullDataDestination
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiFullDataDestination
     */
    longitude?: number;
}

export function ApiFullDataDestinationFromJSON(json: any): ApiFullDataDestination {
    return ApiFullDataDestinationFromJSONTyped(json, false);
}

export function ApiFullDataDestinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFullDataDestination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'workshopId': !exists(json, 'workshopId') ? undefined : json['workshopId'],
        'type': !exists(json, 'type') ? undefined : ApiAssistanceRequestDestinationTypeFromJSON(json['type']),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function ApiFullDataDestinationToJSON(value?: ApiFullDataDestination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'address': value.address,
        'workshopId': value.workshopId,
        'type': ApiAssistanceRequestDestinationTypeToJSON(value.type),
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

