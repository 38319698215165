/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiClaimIncidentType,
    ApiClaimIncidentTypeFromJSON,
    ApiClaimIncidentTypeToJSON,
    ApiClientWorkshopRecommendationRequest,
    ApiClientWorkshopRecommendationRequestFromJSON,
    ApiClientWorkshopRecommendationRequestToJSON,
    ApiWorkshop,
    ApiWorkshopFromJSON,
    ApiWorkshopToJSON,
} from '../models';

export interface GetAllPartnerWorkshopsRequest {
    externalId: number;
    incidentType: ApiClaimIncidentType;
    vehicleMark: string;
    vehicleYear?: number;
}

export interface GetAllPartnerWorkshopsByExternalIdRequest {
    uuid: string;
}

export interface GetRecommendedWorkshopsForClientRequest {
    apiClientWorkshopRecommendationRequest: ApiClientWorkshopRecommendationRequest;
}

/**
 * 
 */
export class PartnerWorkshopApi extends runtime.BaseAPI {

    /**
     * get all partner workshops
     */
    async getAllPartnerWorkshopsRaw(requestParameters: GetAllPartnerWorkshopsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiWorkshop>>> {
        if (requestParameters.externalId === null || requestParameters.externalId === undefined) {
            throw new runtime.RequiredError('externalId','Required parameter requestParameters.externalId was null or undefined when calling getAllPartnerWorkshops.');
        }

        if (requestParameters.incidentType === null || requestParameters.incidentType === undefined) {
            throw new runtime.RequiredError('incidentType','Required parameter requestParameters.incidentType was null or undefined when calling getAllPartnerWorkshops.');
        }

        if (requestParameters.vehicleMark === null || requestParameters.vehicleMark === undefined) {
            throw new runtime.RequiredError('vehicleMark','Required parameter requestParameters.vehicleMark was null or undefined when calling getAllPartnerWorkshops.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentType !== undefined) {
            queryParameters['incidentType'] = requestParameters.incidentType;
        }

        if (requestParameters.vehicleMark !== undefined) {
            queryParameters['vehicleMark'] = requestParameters.vehicleMark;
        }

        if (requestParameters.vehicleYear !== undefined) {
            queryParameters['vehicleYear'] = requestParameters.vehicleYear;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/services/{externalId}/workshops`.replace(`{${"externalId"}}`, encodeURIComponent(String(requestParameters.externalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiWorkshopFromJSON));
    }

    /**
     * get all partner workshops
     */
    async getAllPartnerWorkshops(requestParameters: GetAllPartnerWorkshopsRequest, initOverrides?: RequestInit): Promise<Array<ApiWorkshop>> {
        const response = await this.getAllPartnerWorkshopsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all partner workshops
     */
    async getAllPartnerWorkshopsByExternalIdRaw(requestParameters: GetAllPartnerWorkshopsByExternalIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiWorkshop>>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getAllPartnerWorkshopsByExternalId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{uuid}/workshops`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiWorkshopFromJSON));
    }

    /**
     * get all partner workshops
     */
    async getAllPartnerWorkshopsByExternalId(requestParameters: GetAllPartnerWorkshopsByExternalIdRequest, initOverrides?: RequestInit): Promise<Array<ApiWorkshop>> {
        const response = await this.getAllPartnerWorkshopsByExternalIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns the workshops for a client
     */
    async getRecommendedWorkshopsForClientRaw(requestParameters: GetRecommendedWorkshopsForClientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiWorkshop>>> {
        if (requestParameters.apiClientWorkshopRecommendationRequest === null || requestParameters.apiClientWorkshopRecommendationRequest === undefined) {
            throw new runtime.RequiredError('apiClientWorkshopRecommendationRequest','Required parameter requestParameters.apiClientWorkshopRecommendationRequest was null or undefined when calling getRecommendedWorkshopsForClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/workshops`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: ApiClientWorkshopRecommendationRequestToJSON(requestParameters.apiClientWorkshopRecommendationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiWorkshopFromJSON));
    }

    /**
     * returns the workshops for a client
     */
    async getRecommendedWorkshopsForClient(requestParameters: GetRecommendedWorkshopsForClientRequest, initOverrides?: RequestInit): Promise<Array<ApiWorkshop>> {
        const response = await this.getRecommendedWorkshopsForClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
