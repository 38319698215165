/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceIncidentType,
    ApiAssistanceIncidentTypeFromJSON,
    ApiAssistanceIncidentTypeFromJSONTyped,
    ApiAssistanceIncidentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiClientWorkshopRecommendationRequest
 */
export interface ApiClientWorkshopRecommendationRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiClientWorkshopRecommendationRequest
     */
    externalId: number;
    /**
     * 
     * @type {ApiAssistanceIncidentType}
     * @memberof ApiClientWorkshopRecommendationRequest
     */
    incidentType: ApiAssistanceIncidentType;
    /**
     * 
     * @type {string}
     * @memberof ApiClientWorkshopRecommendationRequest
     */
    vehicleMark?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiClientWorkshopRecommendationRequest
     */
    vehicleAge?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiClientWorkshopRecommendationRequest
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof ApiClientWorkshopRecommendationRequest
     */
    longitude: number;
}

export function ApiClientWorkshopRecommendationRequestFromJSON(json: any): ApiClientWorkshopRecommendationRequest {
    return ApiClientWorkshopRecommendationRequestFromJSONTyped(json, false);
}

export function ApiClientWorkshopRecommendationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClientWorkshopRecommendationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': json['externalId'],
        'incidentType': ApiAssistanceIncidentTypeFromJSON(json['incidentType']),
        'vehicleMark': !exists(json, 'vehicleMark') ? undefined : json['vehicleMark'],
        'vehicleAge': !exists(json, 'vehicleAge') ? undefined : json['vehicleAge'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function ApiClientWorkshopRecommendationRequestToJSON(value?: ApiClientWorkshopRecommendationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'incidentType': ApiAssistanceIncidentTypeToJSON(value.incidentType),
        'vehicleMark': value.vehicleMark,
        'vehicleAge': value.vehicleAge,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

